// 90714
@import '../helpers/componentHeader';

.card-latest-all {
    padding-top: 30px;
    padding-bottom: 30px;
    @include media-breakpoint-down(md) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .linebar-heading {
        text-align: center;
        width: auto;
        margin: 0;
        font-family: $secondary-font-medium;
        font-size: 18px;
        line-height: 19px;
        color: $primary-darkest;
        font-weight: normal;
        letter-spacing: 0;
        @include media-breakpoint-up(md) {
            text-align: center;
            font-size: 36px;
            line-height: 46px;
        }
        
        &:after{
            content:'';
            height: 2px;
            width:64px;
            background: $yellow;
            margin-top:10px;
            margin-bottom: 40px;
            margin-right: auto;
            margin-left: auto;
            display: block;
            @include media-breakpoint-up(md) {
                margin-top:16px;
                margin-bottom: 43px;
            }
            @include media-breakpoint-down(sm){
                margin-bottom: 16px;
            }
        }
    }
    .experience-component {
        %article-title {
            font-family: $primary-font-bold;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: $secondary-black;
            text-align: left;
            @include media-breakpoint-down(sm) {
                font-size: 12px;
                line-height: 16px
            }
        }
        // 69356
        .photo-tile-image {
            height: 235px;
            object-fit: cover;
            @media (min-width: 544px) and (max-width: 767.98px) {
                height: 180px;
            }
            @media (max-width: 543px) {
                min-height: 76px;
                max-height: 100px; 
            }
        }
        .card {
            border-radius: 0px;
        }
        .card-body,
        .modal-body {
            padding:20px;
            @include media-breakpoint-down(sm) {
                padding: 10px;
            }
            .prod-title {
                font-family: $primary-font-semibold;
                font-size: 16px;
                line-height: 26px;
                color: $gray;
                text-align: left;
                font-weight: 400;
                margin-bottom: 6px;
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: uppercase;
                    &:after{
                        content:'';
                        height: 2px;
                        width:30px;
                        background: $yellow;
                        margin-top:15px;
                        margin-bottom: 16px;
                        display: block;
                    }
                }
            }
            .prod-article-title {
                margin-bottom: 12px;
                @extend %article-title;
                @include media-breakpoint-down(sm) {
                    color: $primary;
                    text-transform: uppercase;
                }

                a {
                    @extend %article-title;
                    }
                }
                .prod-description {
                    font-family: $primary-font;
                    font-size: 12px;
                    line-height: 24px;
                    color: $black;
                    text-align: left;
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
                .btn-readmore {
                    font-family: $primary-font-semibold;
                    font-size: 16px;
                    line-height: 26px;
                    color: $primary;
                    background-image: url(../../../images/long-arrow-blue.svg);
                    background-repeat: no-repeat;
                    background-position: right 4px;
                    padding-right: 23px;
                    background-size: 14px;
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
        }
        
        .product-video-thumbnail {
            position: relative;
            overflow: hidden;
            @media (max-width: 1024px) {
                width: 100%;
            }
            .product-thumbnail {
                height: 100%;
                width: 100%;
                height: 235px;
                object-fit: cover;
                @media (min-width: 991px) and (max-width: 1024px) {
                    height: 275px;
                }
                @media (min-width: 544px) and (max-width: 767.98px) {
                    height: 180px;
                }
                @media (max-width: 543px) {
                    min-height: 76px;
                    max-height: 100px; 
                }
            }
            .video-mask {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.3);
                -webkit-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            .btn-video-popup {
                background: url("../../images/play-video.svg");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 36px;
                height: 36px;
                border: 0px;
                position: absolute;
                margin: auto;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
        .modal {
            @include media-breakpoint-down(sm) {
                padding: 0px!important;
            }
            .modal-dialog {
                &.modal-lg {
                    max-width: 750px;
                    .modal-content {
                        .modal-header {
                            .close {
                                font-family: $primary-font-light;
                                color: $primary;
                                opacity: 1;
                                @media (max-width:767.98px) {
                                    color: $white;
                                }
                            }
                        }
                        .modal-body {
                            padding: 16px 32px;
                            @include media-breakpoint-down(sm) {
                                padding: 16px 0px;
                                color: $white;
                            }
                            .prod-title,
                            .prod-article-title a {
                                @include media-breakpoint-down(sm) {
                                    padding: 0 16px;
                                    color: $white;
                                }
                            }
                            .prod-article-title {
                                @include media-breakpoint-down(sm) {
                                    color: $white;
                                } 
                            }
                            .prod-description {
                                @include media-breakpoint-down(sm) {
                                    padding: 0 16px;
                                    color: $white;
                                    opacity: .85;
                                }
                            }
                        }
                    }
                }

                @media (max-width:767.98px) {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    max-width: 100%;

                    .modal-content {
                        height: auto;
                        min-height: 100%;
                        border-radius: 0;
                        border: 0px;
                        padding-top: 50px;
                        background: rgba(0,0,0,.65);
                    }
                }
            }
        }
        .vertical-article,
        .vertical-video-article  {
            height: 100%;
            .card-body {
                display:flex;
                flex-flow: column;
                flex: 1 0 0%;
            }
        }
        .blog-feed {
            .card {
                border: 0px;
                border-bottom: 1px solid $primary;
                border-radius: 0px;
                padding: 20px 0px;
                margin: 0px;
                @include media-breakpoint-down(sm) {
                    padding: 25px 0px 10px; 
                }
                &:first-child {
                    padding-top: 0px;
                }
                .card-body {
                    padding: 0px;
                    .prod-article-title {
                        margin-bottom: 6px;
                    }
                    .prod-description {
                        margin: 0px;
                        &.block-ellipsis {
                            @media (min-width: 1025px) {
                                display: block;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                height: 78px;
                                max-height: 70px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-box-orient: vertical;
                                /* autoprefixer: off */
                            }
                        }
                    }
                }
            }
        }
    }
}
// 49178
// 52197
// 57517
// 58171
// 64614